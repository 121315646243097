.previewModal {
    // .row_heading {
    //     font-size: 16px;
    // }

    .box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            color: #ADADAD;
            font-family: "Montserrat", sans-serif;
            font-size: 15px;
            // font-weight: 700;
        }
    }

    .bottom_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        p {
            color: #FF2121;
            font-family: "Montserrat", sans-serif;
            font-size: 16px;
            font-weight: 700;
        }
    }

    /* PREVIEW MODAL ANT-TABLE */
    .ant-table {

        table {
            background-color: #1a1a1a !important;
            border-spacing: 12px !important;

            .ant-table-thead>tr>th {
                border: 2px solid #1a1a1a !important;
                text-align: start !important;
            }

            .ant-table-tbody>tr>td:first-child {
                border: 2px solid #1a1a1a !important;
            }

            .ant-table-tbody>tr>td:not(:first-child) {
                border: 2px solid #424242 !important;
                border-radius: 8px !important;
            }
        }
    }
}

.party_order_table,
.menu_table,
.employee_table {
    .ant-table-thead>tr>th {
        text-align: start !important;
        ;
    }
}

.calculation_input_outer {
    .form-group {
        max-width: 200px;
    }
}

.action_btn {
    border: 0;
    background-color: transparent;
    color: #808080;
    font-size: 22px;

    &:hover {
        color: var(--primary_red);
    }
}