@import url("./styles/addonclass.css");
@import url("./styles/antdstyle.css");

body * {
  font-variant: tabular-nums;
}

.select-focus {
  &>div {
    height: 100%;
    background: white;
  }

  .react-dropdown-select-content.react-dropdown-select-type-single {
    margin-top: auto;
  }
}

.react-dropdown-select {
  height: 100%;
  background: white;
}

.react-dropdown-select-content.react-dropdown-select-type-single {
  padding-inline: 10px;
}

.accordion-collapse.collapse {
  visibility: initial;
}